<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="tariffsUpdate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Name"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      vid="name"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Display Name"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Display Name"
                      vid="display_name"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.display_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Display Name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Price"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Price"
                      vid="price"
                      rules="price"
                    >
                      <b-form-input
                        v-model="form.price"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Price"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Currency"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      vid="currency_id"
                    >
                      <v-select
                        v-model="form.currency_id"
                        :options="currencyList"
                        :searchable="false"
                        :reduce="currency => currency.id"
                        label="code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Is VAT"
                    label-class="label-class"
                  >
                    <b-form-checkbox
                      v-model="form.is_vat"
                      class="mr-0"
                      switch
                      inline
                      style="height: 40px"
                      @input="form.vat = null"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    v-if="form.is_vat"
                    label="VAT"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="VAT"
                      vid="vat"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.vat"
                        :state="errors.length > 0 ? false:null"
                        placeholder="VAT"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Download Speed"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Download Speed"
                      vid="download_speed"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.download_speed"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Download Speed"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Upload Speed"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Upload Speed"
                      vid="upload_speed"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.upload_speed"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Upload Speed"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Limit At"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Limit At"
                      vid="limit_at"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.limit_at"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Limit At"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Burst limit"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Burst limit"
                      vid="burst_limit"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.burst_limit"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Burst limit"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Burst threshold"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Burst threshold"
                      vid="burst_threshold"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.burst_threshold"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Burst threshold"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Burst time"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Burst time"
                      vid="burst_time"
                      rules="integer|max:255"
                    >
                      <b-form-input
                        v-model="form.burst_time"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Burst time"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Priority List"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Priority List"
                      vid="priority"
                    >
                      <v-select
                        v-model="form.priority"
                        :options="priorityList"
                        :searchable="false"
                        :reduce="priority => priority.id"
                        label="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Owner"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Owner"
                      vid="company_id"
                    >
                      <v-select
                        v-model="form.company_id"
                        :options="companyList"
                        :reduce="company => company.id"
                        label="name"
                        @search="onSearchCompany($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Other Tariffs"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other Tariffs"
                      vid="otherTariffIds"
                    >
                      <v-select
                        v-model="form.otherTariffIds"
                        :options="tariffList"
                        :reduce="tariff => tariff.id"
                        label="name"
                        :searchable="false"
                        multiple
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Is enabled"
                  label-class="label-class"
                >
                  <b-form-checkbox
                    v-model="form.is_enabled"
                    class="mr-0"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>
            </b-col></b-row>
          <!-- submit button -->
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import { required, max, integer } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
    vSelect,

  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        display_name: null,
        price: null,
        download_speed: null,
        upload_speed: null,
        is_vat: true,
        vat: null,
        limit_at: null,
        burst_limit: null,
        burst_threshold: null,
        burst_time: null,
        is_enabled: true,
        priority: null,
        otherTariffIds: [],
        company_id: null,
        currency_id: null,
      },
      required,
      max,
      integer,
    }
  },
  computed: {
    ...mapState('tariffs', {
      priorityList: 'priorityList',
      companyList: 'companyList',
      tariffList: 'tariffList',
      currencyList: 'currencyList',
      item: 'item',
    }),
  },
  async mounted() {
    const { id } = this.$route.params
    this.loading = true
    if (id) {
      await Promise.all([this.getPriority(), this.getList(), this.getCurrencyList()])
      await this.fetchModel(id)
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('tariffs', [
      'getPriority',
      'getCompanies',
      'getList',
      'getItem',
      'getCurrencyList',
      'updateItem',
    ]),
    onSearchCompany(query) {
      if (!query) {
        return
      }
      this.getCompanies(query)
    },
    async fetchModel(id) {
      try {
        await this.getItem(id)
        this.form.name = this.item.name
        this.form.id = this.item.id
        this.form.display_name = this.item.display_name
        this.form.price = this.item.price
        this.form.download_speed = this.item.download_speed
        this.form.upload_speed = this.item.upload_speed
        this.form.is_vat = this.item.is_vat
        this.form.vat = this.item.vat
        this.form.limit_at = this.item.limit_at
        this.form.burst_limit = this.item.burst_limit
        this.form.burst_threshold = this.item.burst_threshold
        this.form.burst_time = this.item.burst_time
        this.form.is_enabled = this.item.is_enabled
        this.form.priority = this.item.priority
        if (this.item.company) {
          this.getCompanies(this.item.company.name)
          this.form.company_id = this.item.company.id
        }
        if (this.item.otherTariffs) {
          this.item.otherTariffs.forEach(item => {
            this.form.otherTariffIds.push(item.id)
          })
        }
        if (this.item.currency) {
          this.form.currency_id = this.item.currency.id
        }
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async validationForm() {
      const valid = await this.$refs.tariffsUpdate.validate()
      if (valid) {
        try {
          await this.updateItem(this.form)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'tariffs-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.tariffsUpdate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
